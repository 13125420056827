



































































import Vue from "vue";
import VJsf from "@koumoul/vjsf/lib/VJsf.js";
import "@koumoul/vjsf/lib/VJsf.css";
import "@koumoul/vjsf/lib/deps/third-party.js";
import { ModuleInstanceModel, Terminal, ConnectionID, ModuleInstanceID } from "@/modules/evbc";
import EVConfigModel from "@/modules/evbc/config_model";
import ConfigStageContext from "@/modules/evconf_konva/stage_context";

export default Vue.extend({
  // data() {
  //   return {
  //     model: {},
  //   };
  // },
  computed: {
    // FIXME (aw): this is not how it works!
    module_node(): { instance_id: number; instance: ModuleInstanceModel } {
      const instance_id = this.$store.getters["evbc/selected_module_instance"];
      if (instance_id === null) {
        return null;
      }
      const instance = this.config_model.get_module_instance(instance_id);
      return {
        instance_id,
        instance,
      };
    },
    terminal(): Terminal {
      return this.$store.getters["evbc/selected_terminal"];
    },
    config_model: function (): EVConfigModel {
      return this.$store.getters["evbc/current_config"];
    },
    connection: function (): {
      from: { type: string; id: string; name: string };
      to: { type: string; id: string; name: string };
      id: string;
    } {
      const connection_id = this.$store.getters["evbc/selected_connection"];
      if (connection_id === null) {
        return null;
      }
      const cxn = this.config_model.get_connection(connection_id);
      const requiring_module = this.config_model.get_module_instance(cxn.requiring_instance_id);
      const implementing_module = this.config_model.get_module_instance(cxn.providing_instance_id);

      return {
        from: {
          type: implementing_module.type,
          id: implementing_module.id,
          name: cxn.providing_impl_name,
        },
        to: {
          type: requiring_module.type,
          id: requiring_module.id,
          name: cxn.requirement_name,
        },
        id: connection_id,
      };
    },
    context: function (): ConfigStageContext {
      return this.$store.getters["evbc/config_context"];
    },
    moduleIDRules: function () {
      return [
        (v: string) => {
          const instance_id = this.module_node.instance_id;
          const result = this.config_model.update_module_id(instance_id, v);
          return result || "This module id is not available";
        },
      ];
    },
  },
  methods: {
    delete_connection(id: ConnectionID) {
      this.config_model.delete_connection(id);
    },
    delete_module_instance(id: ModuleInstanceID) {
      this.config_model.delete_module_instance(id);
    },
  },
  components: {
    VJsf,
  },
});
