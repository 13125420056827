





















































































import Vue from "vue";

type ServerItem = {
  id: string;
  addr: string;
  editable: boolean;
};

// BIG FIXME (aw): the needs to be refactored graphically and logically

export default Vue.extend({
  data: () => ({
    servers: [
      {
        id: "Loopback",
        addr: "loopback",
        editable: false,
      },
    ] as ServerItem[],
    edit_item: null as { is_add: boolean; index: number; server: ServerItem },
    connect_automatically: false as boolean,
    connecting: false as boolean,
    connection_status: null as string,
    error: { active: false, status: "" },
  }),
  methods: {
    add_server() {
      this.edit_item = {
        is_add: true,
        index: null,
        server: {
          id: "Example Instance",
          addr: "8.8.4.8",
          editable: true,
        },
      };
    },
    edit_server(index: number) {
      this.edit_item = {
        is_add: false,
        index,
        server: {
          ...this.servers[index],
        },
      };
    },
    submit_edit() {
      const server_item = {
        ...this.edit_item.server,
      };
      if (this.edit_item.is_add) {
        this.servers.push(server_item);
      } else {
        this.servers[this.edit_item.index] = server_item;
      }
      this.close_edit();
      this.submit_local_storage_settings();
    },
    close_edit() {
      this.edit_item = null;
    },
    delete_item(index: number) {
      this.servers.splice(index, 1);
      this.close_edit();
      this.submit_local_storage_settings();
    },
    submit_local_storage_settings() {
      window.localStorage.setItem(
        "evbc-settings",
        JSON.stringify({
          servers: this.servers,
          connect_automatically: this.connect_automatically,
        })
      );
    },
    connect(server: ServerItem) {
      this.connecting = true;
      this.$evbc.connect(server.addr);
    },
  },
  created() {
    const storage = window.localStorage;
    const evbc_ls_string = storage.getItem("evbc-settings");

    if (evbc_ls_string) {
      const evbc_local_storage = JSON.parse(evbc_ls_string);
      if ("servers" in evbc_local_storage) {
        this.servers = (evbc_local_storage.servers as ServerItem[]).map((item) => ({ ...item }));
      }

      if ("connect_automatically" in evbc_local_storage) {
        this.connect_automatically = evbc_local_storage.connect_automatically;
      }
    }

    const unsubscribe = this.$evbc.on("connection_state", (ev) => {
      if (ev.type === "INFO") {
        this.connection_status = ev.text;
      } else if (ev.type === "INITIALIZED") {
        unsubscribe();
        this.$router.push({ name: "main" });
      } else if (ev.type === "FAILED") {
        this.connecting = false;
        this.error = { active: true, status: ev.text };
      }
    });

    if (this.connect_automatically) {
      this.connect(this.servers[0]);
    }
  },
});
