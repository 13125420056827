










import Vue from "vue";

export default Vue.extend({
  data() {
    const reactive = {
      ls: null as string,
    };

    return reactive as typeof reactive & {
      //
    };
  },
  computed: {
    nodes() {
      return null;
    },
  },
  created() {
    // FIXME (aw): can we assume, localStorage always exists?
    const storage = window.localStorage;
    const ls_item = storage.getItem("ls");
    if (!ls_item) {
      this.ls = `No item named 'ls' in our storage :(`;
      storage.setItem("ls", "Saved stuff");
    } else {
      this.ls = ls_item;
    }
  },
  mounted() {
    //
  },
  methods: {
    loadConfig() {
      //
    },
  },
  components: {
    //
  },
});
