export const COLOR = {
  TERMINAL_REQUIREMENT: "#ffd04e",
  TERMINAL_REQUIREMENT_LIGHT: "#FFE8A7",
  TERMINAL_PROVIDE: "#679cff",
  TERMINAL_PROVIDE_LIGHT: "#B3CEFF",
  CONNECTION: "#CCC",
};

export const ICON_DATA = {
  EDIT: "M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z",
  GRAB: "M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z",
  TERMINAL:
    "M12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M17,14L12,9L7,14H17Z",
  DISABLED:
    "M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z",
};

export const SIZE = {
  GRID: 24,
  FRAME_WIDTH: 288,
  FRAME_HEIGHT: 144,
  TERMINAL: 24,
  CONNECTION_WIDTH: 4,
  CONNECTION_CTRL: 100,
};
