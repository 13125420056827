
















import Vue from "vue";

import EvConfigCanvas from "../components/EvConfigCanvas.vue";
import EvModuleList from "../components/EvModuleList.vue";
import EvModuleInfo from "../components/EvModuleInfo.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  data() {
    const reactive = {
      active_config: null as string,
    };

    return reactive as typeof reactive & {
      //
    };
  },
  computed: {
    ...mapGetters("evbc", ["current_config"]),
  },
  components: {
    EvConfigCanvas,
    EvModuleList,
    EvModuleInfo,
  },
});
