import { PluginObject } from "vue";
import EVBackendClient from "@/modules/evbc/client";

export const evbc = new EVBackendClient();

export default {
  install: function (Vue) {
    Vue.prototype.$evbc = evbc;
  },
} as PluginObject<void>;
