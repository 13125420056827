



















































import Vue from "vue";
import EVConfigModel from "@/modules/evbc/config_model";

export default Vue.extend({
  computed: {
    current_config(): EVConfigModel {
      return this.$store.getters["evbc/current_config"];
    },
    module_list(): Array<{ type: string; description: string }> {
      return Object.entries(this.$evbc.everest_definitions.modules).map(([key, value]) => ({
        type: key,
        description: value.description,
      }));
    },
    config_list(): Array<string> {
      const configs: Record<string, unknown> = this.$evbc._configs;
      return Object.entries(configs).map(([key]) => key);
    },
  },
  methods: {
    add_module_to_config(type: string) {
      // FIXME (aw): does this logic belongs to here?
      if (this.current_config) {
        this.current_config.add_new_module_instance(type);
      } else {
        const new_config = this.$evbc.create_empty_config("test_config");
        new_config.add_new_module_instance(type);
        this.$store.dispatch("evbc/set_opened_config", new_config);
      }
    },
    load_config(name: string) {
      const new_config = this.$evbc.load_config(name);
      this.$store.dispatch("evbc/set_opened_config", new_config);
    },
    execute(command: string) {
      this.$evbc.execute_remote_command(command);
    },
  },
});
